import { createSlice } from "@reduxjs/toolkit";

const initialCompetitionState = {
    isLoading: false,
    payload: null,
    retrieveApartmentListResponse: null,
    retrieveApartmentListError: null,
};


export const apartmentList_slice = createSlice({
    name: "apartmentListReducer",
    initialState: initialCompetitionState,
    reducers: {
        // // RETRIEVE ALL APARTMENT REDUCER
        retrieveApartmentList: (state, action) => {
            state.isLoading = true;
            state.retrieveApartmentListResponse = null;
            state.retrieveApartmentListError = null;
        },
        retrieveApartmentListSuccess: (state, action) => {
            state.isLoading = false;
            state.retrieveApartmentListResponse = action.payload;
        },
        retrieveApartmentListFailure: (state, action) => {
            state.isLoading = false;
            state.retrieveApartmentListError = action.payload;
        },

    },


    extraReducers: {

    },
});
