import { clientBaseURL } from "./baseURLconfig";
import { PublicClientApplication } from "@azure/msal-browser";

// Config object to be passed to Msal on creation
export const msalInstance = new PublicClientApplication(
    {
        auth: {
            clientId: "e843cc84-d56e-45d1-83c7-00c0691d9716",
            authority: "https://measuresol.b2clogin.com/measuresol.onmicrosoft.com/B2C_1_MeasureSignIn",
            knownAuthorities: ["measuresol.b2clogin.com"],
            redirectUri: `${clientBaseURL}`,
            postLogoutRedirectUri: `${clientBaseURL}`
        }
    }
);

// Scopes you add here will be prompted for consent during login
export const loginRequest = {
    scopes: ["https://measuresol.onmicrosoft.com/dfa3f6f0-dbd2-4cbb-99a3-db5e98cdcd33/api.readwrite"]
};



// // ----------------------------------------------------------------------------
// // Config object to be passed to Msal on creation
// export const msalConfig = {
//     auth: {
//         clientId: "9067c884-9fa6-414f-9aa4-a565b1cb46be",
//         authority: "https://fabrikamb2c.b2clogin.com/fabrikamb2c.onmicrosoft.com/b2c_1_susi_reset_v2",
//         knownAuthorities: ["fabrikamb2c.b2clogin.com"],
//         redirectUri: "http://localhost:4200",
//         postLogoutRedirectUri: "http://localhost:4200"
//     }
// };

// // Scopes you add here will be prompted for consent during login
// export const loginRequest = {
//     scopes: ["https://fabrikamb2c.onmicrosoft.com/helloapi/demo.read"]
// };
