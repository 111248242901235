import { createSlice } from "@reduxjs/toolkit";

const initialCompetitionState = {
    isLoading: false,
    payload: null,
    authResponse: null,
    authError: null,
};


export const authSlice = createSlice({
    name: "authReducer",
    initialState: initialCompetitionState,
    reducers: {
        // // RETRIEVE AUTH REDUCER
        auth: (state, action) => {
            state.isLoading = true;
            state.authResponse = null;
            state.authError = null;
        },
        authSuccess: (state, action) => {
            state.isLoading = false;
            state.authResponse = action.payload;
        },
        authFailure: (state, action) => {
            state.isLoading = false;
            state.authError = action.payload;
        },
    },


    extraReducers: {

    },
});
