import React, { Fragment, Suspense } from 'react'

// // import Cascading Style Sheets
import './App.css';
import "src/assets/sass/main.scss"

// // Font Awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { faBatteryFull, faPlug, faBurn, faThermometerHalf, faSyncAlt, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'

// // localization 
import "src/utils/locales/i18n"

// // REDUX store import
import { Provider } from 'react-redux'
import { store } from 'src/redux/store';

// // MSAL imports
import { MsalProvider } from "@azure/msal-react";
import { msalInstance } from 'src/helper/config/authConfig';


// // RootNavigation component import
import RootNavigation from 'src/containers/RootNavigation';
// import FinAppNavigation from 'src/utils/finapp/FinAppNavigation';

library.add(faBatteryFull, faPlug, faBurn, faThermometerHalf, faSyncAlt, faPlus, faMinus)

function App() {
  return (
    <Fragment >
      <Suspense fallback={(<div>Loading</div>)}>
        <Provider store={store} >
          <MsalProvider instance={msalInstance}>
            <RootNavigation />
            {/* <FinAppNavigation /> */}
          </MsalProvider>
        </Provider>
      </Suspense>
    </Fragment>
  );
}

export default App;
