// Api base url depend on environment
const handleGetApiBaseURL = () => {
    //return process.env.REACT_APP_API_BASE_URL || 'https://app.levikaira.meso.fi/api';
    return process.env.REACT_APP_API_BASE_URL || 'https://localhost:44371';
}

// Client base url depend on environment
const handleGetClientBaseURL = () => {
    return process.env.REACT_APP_BASE_URL || 'http://localhost:5000';
}


export const apiBaseURL = handleGetApiBaseURL()
export const clientBaseURL = handleGetClientBaseURL()