import React, { Fragment, useState, useRef, useCallback, useEffect } from 'react'
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
// Msal imports
import { useIsAuthenticated, useMsal, useAccount } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { loginRequest } from 'src/helper/config/authConfig';
import { useHistory } from "react-router";
import SignInSignOutButton from 'src/components/signInSignOutButton/SignInSignOutButton'
import { Spinner } from 'react-bootstrap';
// import { darkModeHelperFunction } from 'src/helper/functions/darkModeHelper';

import measureLogo from "src/assets/icons/measureLogo.svg"
import iot_measure from "src/assets/img/iot_measure.jpg"


function SplashScreen() {
    // // ----------Localization hooks & Router Hooks-------------
    const { t, i18n } = useTranslation('common');
    const history = useHistory();

    // MSAL
    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0] || {});
    instance.setActiveAccount(account);


    const [atsResponse, setAtsResponse] = useState(null);
    const [isProgressLoading, setIsProgressLoading] = useState(true)


    let userToken = Cookies.get('levikairaAuth');
    useEffect(() => {
        if (isAuthenticated) {
            history.push('/apartmentlist')
        }
        else if (userToken) {
            history.push('/apartmentlist')
        }

        return () => {

        }
    }, [isAuthenticated, userToken])


    useEffect(() => {
        if (inProgress === "startup" || inProgress === "handleRedirect") {
            setIsProgressLoading(true)
        }
        else if (inProgress === "none") {
            setIsProgressLoading(false)
        }

        if (!atsResponse && account && inProgress === InteractionStatus.None) {
            instance.acquireTokenSilent({
                ...loginRequest,
                account: account
            }).then((response) => {
                setAtsResponse(response);
                if (response) {
                    // window.location.replace("/dashboard")
                    history.push({
                        pathname: "/apartmentlist",
                    });
                }
                Cookies.set('levikairaAuth', response.accessToken);
            });
        }
        return () => {

        }
    }, [account, inProgress, instance, atsResponse]);


    // useEffect(() => {
    //     darkModeHelperFunction()
    // }, [])


    const handleLogin = (loginType) => {
        if (loginType === "popup") {
            // Login with Popup
            instance.loginPopup(loginRequest).catch(e => {
                return;
            });
        } else if (loginType === "redirect") {
            // Login with Redirect
            instance.loginRedirect(loginRequest);
        }
        else {
            // Login with Redirect
            instance.loginRedirect(loginRequest);
        }
    }


    return (
        <Fragment>
            {isProgressLoading ?
                <div className="splash__progressLoading">
                    <Spinner animation="border" variant="primary" />
                </div>
                :
                (
                    <div className="splash__container">
                        <div className="appHeader no-border justify-content-center">
                            <div className="">
                                <img src={measureLogo} alt="measureLogo" className="splash__logo" />
                            </div>
                        </div>

                        <div id="" className="justify-content-center">
                            <img src={iot_measure} alt="iot_measure" style={{ margin: '0.625rem' }} className="splash__img" />
                            {/* <p>bharataklsfsdfkldklsdf sdfjklkldfsssssssssssssssssssssssss ssssssssssssssdsbharataklsf sdfkldklsdfsdfjklkld fssssssssssssssssssssss sssssssssssssssssds</p> */}
                            <div className="section">
                                <div className="splash-page mt-5 mb-5">

                                    <h2 className="mb-2">{t('splash_heading')}</h2>
                                    <p>
                                        {t('splash_paragraph')}
                                    </p>
                                </div>
                            </div>

                        </div>
                        <div id="" className="justify-content-center">
                            <div className="col-9 splash__login">
                                <button onClick={() => handleLogin()} className="btn btn-lg btn-primary btn-block">{t("click here to continue")}</button>
                            </div>
                        </div>
                    </div>
                )
            }
        </Fragment>
    )
}

export default SplashScreen
