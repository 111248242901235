import React from 'react'
import sisasivut_ylakuva from "src/assets/img/sisasivut_ylakuva.jpg"

function AboutUs() {
    return (
        <div>
            <div id="appCapsule">
                <img src={sisasivut_ylakuva} alt="sisasivut_ylakuva" style={{ width: "100%" }} />

            </div>
            <div className="p-5 mb-4 ">
                <div>

                    <h1>Etäluettavat vesimittarit</h1>
                    <p>Vesimittarilukemien kerääminen asukkailta on työlästä, virhealtista ja hidasta, eikä lainkaan tätä päivää.</p>
                    <p>Me tarjoamme ratkaisun, jossa etäluettavat vesimittarit tarjoavat mittarilukemat automaattisesti vähintään kerran vuorokaudessa, ja tämä tieto voidaan viedä saumattomasti aina laskutusjärjestelmäänne saakka.</p>
                    <h2>Paimion Vesilaitos Oy automatisoi mittariluennan Measuren ratkaisulla</h2>
                    <p>Measure Solution Oy&nbsp;on rakentanut&nbsp;<a href="http://www.paimio.fi/">Paimion Vesihuolto Oy</a>:lle IoT-kokonaisratkaisun, joka tuo vesimittarit ja niiden luennan kertaloikalla 2020-luvulle. Esineiden internettiin kehitetty maanlaajuinen&nbsp;<a href="https://www.sigfox.com/">Sigfox</a>-verkko ja uudet älykkäät vesimittarit mahdollistavat etäluennan alkuvaiheessa noin viidessäsadassa kodissa Paimion alueella. Veden kulutusmittauksen lisäksi älykkäät vesimittarit mahdollistavat hälytykset muun muassa mahdollisista piilevistä vuodoista.</p>
                    <p>Etäluettavat vesimittarit ovat myös perustekniikaltaan aiemmin yleisimmin käytettyjä mekaanisia mittareita kestävämpiä ja mittaavat kulutusta merkittävästi tarkemmin. Ultraääniteknologiaa käyttävien mittareiden elinkaaren ennustetaan olevan vähintään 15 vuotta.</p>
                    <p>Etäluenta tuottaa Paimion Vesihuollolle merkittäviä säästöjä kulutuslukemien keräämisen ollessa jatkossa täysin automaattista. Asiakkaille uudistus näkyy erityisesti vuosittaisen kulutuslukemien ilmoittamisen poistumisena.</p>
                    <p>Sigfox-teknologiaan ja Measure Solutionin ratkaisuun Paimion Vesihuolto Oy päätyi erityisesti huolettomuuden ja kustannustehokkuuden takia. Measure Solution toteutti ratkaisun avaimet käteen-periaatteella eikä järjestelmän operointiin jatkossa kulu lainkaan henkilötyötä.</p>
                    <p>Tämä uutinen löytyy myös kumppanimme&nbsp;<a href="https://www.connectedfinland.fi/lehdistotiedotteet-fi/connected-finlandin-kumppani-measure-solution-tuottaa-etaluettavan-vesimittauksen-paimiossa/" target="_blank">Connected Finlandin sivuilta</a>.</p>
                     </div>
            </div>
        </div>
    )
}

export default AboutUs
