import axios from "axios";
import Cookies from 'js-cookie';
// import { store } from "src/redux/store";
import { apiBaseURL } from './baseURLconfig';

import { msalInstance, loginRequest } from 'src/helper/config/authConfig';

/**
 * Interceptors are a feature that allows an application to intercept requests or responses before they are handled by the .then() or the .catch().
 * There are 2 type of interceptor 1) interceptors.request   &&   2) interceptors.response
 * Both types of Axios interceptors accept two functions. 
 * The first function of the request interceptor modifies the request if it’s a valid, successful request, 
 * the second function handles when the request is invalid and throws an error.
**/

const AxiosConfig = () => {
    const axiosInstance = axios.create();
    axiosInstance.defaults.baseURL = apiBaseURL;

    // interceptors Request------------------------------------
    axiosInstance.interceptors.request.use(
        async config => {
            const tokenResponse = await msalInstance.acquireTokenSilent({
                ...loginRequest
            });
            
            let userToken = tokenResponse.accessToken;

            let token = userToken ? userToken : '';

            if (!!token) {
                config.headers = {
                    ...config.headers,
                    'Authorization': "bearer " + token
                };
            }
            return config;
        },
        (error) => {
            return new Promise((resolve, reject) => {
                reject(error);
            });
        }
    );

    //validating the token expiration scenario --------------------------
    // interceptors Response------------------------------------
    axiosInstance.interceptors.response.use(
        (Response) => {
            return Response
        },
        (error) => {
            if (
                    ( error.errorCode && ( error.errorCode === "no_account_error" || error.errorCode === "silent_sso_error" ) )
                    || error.response && error.response.status === 401
                ) {
                Cookies.remove('levikairaAuth');
                const logoutRequest = {
                    account: msalInstance.getActiveAccount()
                }
                msalInstance.logoutRedirect(logoutRequest);
                return;
            }

            return new Promise((resolve, reject) => {
                reject(error);
            });
        }
    );

    return axiosInstance;
}

export default AxiosConfig;
