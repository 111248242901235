import { createSlice } from "@reduxjs/toolkit";

const initialCompetitionState = {
    isLoading: false,
    payload: null,
    retrieveRoomListResponse: null,
    retrieveRoomListError: null,
};


export const roomList_slice = createSlice({
    name: "roomListReducer",
    initialState: initialCompetitionState,
    reducers: {
        // // RETRIEVE ALL ROOM REDUCER
        retrieveRoomList: (state, action) => {
            state.isLoading = true;
            state.retrieveRoomListResponse = null;
            state.retrieveRoomListError = null;
        },
        retrieveRoomListSuccess: (state, action) => {
            state.isLoading = false;
            state.retrieveRoomListResponse = action.payload;
        },
        retrieveRoomListFailure: (state, action) => {
            state.isLoading = false;
            state.retrieveRoomListError = action.payload;
        },
    },


    extraReducers: {

    },
});
