import React from 'react'
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import 'moment/locale/fi';

function Settings() {
    // // ----------Localization hooks & Router Hooks-------------
    const { t, i18n } = useTranslation('common');

    // Set the locale for every react-moment instance to French.
    Moment.globalLocale = 'fi';

    return (

        <div id="appCapsule">

            <div className="section mt-2 text-center">
                <h1>{t("Profile Page")}</h1>
            </div>
            <div className="section mb-5 p-2">
                <form action="index.html">
                    <div className="card">
                        <div className="card-body">
                        <div className="form-group basic">
                                <div className="input-wrapper">
                                    <label className="label" htmlFor="name">{t("Name")}</label>
                                    <input type="text" className="form-control" id="name" autoComplete="off" placeholder={t("Your Name")} />
                                    <i className="clear-input">
                                        <ion-icon name="close-circle" role="img" className="md hydrated" aria-label="close circle"></ion-icon>
                                    </i>
                                </div>
                            </div>
                            <div className="form-group basic">
                                <div className="input-wrapper">
                                    <label className="label" htmlFor="password1">{t("Password")}</label>
                                    <input type="password" className="form-control" id="password1" autoComplete="off" placeholder={t("Your password")}/>
                                    <i className="clear-input">
                                        <ion-icon name="close-circle" role="img" className="md hydrated" aria-label="close circle"></ion-icon>
                                    </i>
                                </div>
                            </div>

                            <div className="form-group basic">
                                <div className="input-wrapper">
                                    <label className="label" htmlFor="email1">{t("E-mail")}</label>
                                    <input type="email" className="form-control" id="email1" placeholder={t("Your e-mail")} />
                                    <i className="clear-input">
                                        <ion-icon name="close-circle" role="img" className="md hydrated" aria-label="close circle"></ion-icon>
                                    </i>
                                </div>
                            </div>
                            <div className="form-group basic">
                                <div className="input-wrapper">
                                    <label className="label" htmlFor="password2">{t("Mobile Number")}</label>
                                    <input type="number" className="form-control" id="mobileNumber" autoComplete="off" placeholder={t("Your Mobile Number")} />
                                    <i className="clear-input">
                                        <ion-icon name="close-circle" role="img" className="md hydrated" aria-label="close circle"></ion-icon>
                                    </i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-5">
                        <button type="submit" className="btn btn-primary btn-block btn-lg">{t("Update")}</button>
                    </div>

                </form>
            </div>

        </div>
    )
}

export default Settings
