import React, { Fragment, useState, useEffect } from 'react'
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import { sideNavbarMenueShowActions } from 'src/redux/globalState_redux/globalState_action';
import { useMsal } from "@azure/msal-react";
import { useLocation } from "react-router-dom";
import measureLogo from "src/assets/icons/measureLogo.svg"



function Header(props) {
    // // ----------Localization hooks & Router Hooks-------------
    const { t, i18n } = useTranslation('common');


    const history = useHistory()
    const location = useLocation();

    // // ----------redux store useDispatch & useSelector --------------------
    const dispatch = useDispatch()
    const { instance } = useMsal();


    let bottomTabScreen
    if (location.pathname === "/dashboard" || location.pathname === "/apartmentlist" || location.pathname === "/settings") {
        bottomTabScreen = true
    }
    else {
        bottomTabScreen = false
    }


    // // ----------handler------------------------------------
    const handleLogout = () => {
        Cookies.remove('levikairaAuth');
        instance.logoutRedirect();
    }

    const handleSidebarShow = () => {
        dispatch(sideNavbarMenueShowActions())
    }


    return (
        <Fragment>
            <div className="appHeader text-light header__container">
                <div className="left">
                    {bottomTabScreen ?
                        (<div className="headerButton header__btn" data-bs-toggle="modal" data-bs-target="#sidebarPanel" onClick={() => handleSidebarShow()}>
                            <ion-icon name="menu-outline" className="colorPrimary"></ion-icon>
                        </div>)
                        :
                        (<div onClick={() => history.goBack()} className="headerButton goBack header__btn">
                            <ion-icon name="arrow-back-outline"></ion-icon>
                        </div>)
                    }
                </div>
                <div className="pageTitle">
                    <img className="logo header__measureLogo" src={measureLogo} />
                </div>
                <div className="right">
                    {/* <Link to="/notifications" className="headerButton">
                        <ion-icon className="icon" name="notifications-outline"></ion-icon>
                        <span className="badge badge-danger">4</span>
                    </Link> */}
                    {bottomTabScreen &&
                        <div className="headerButton header__btn" onClick={() => handleLogout()}>
                            <ion-icon name="log-out-outline" className="imaged w32"></ion-icon>
                        </div>
                    }
                </div>
            </div>
        </Fragment>
    )
}

export default Header
