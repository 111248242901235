import { createSlice } from "@reduxjs/toolkit";

const initialCompetitionState = {
    isLoading: false,
    payload: null,
    retrieveRoomDevicesResponse: null,
    retrieveRoomDevicesError: null,
    updateRoomDevicesTargetTempResponse: null,
    updateRoomDevicesTargetTempError: null,
};


export const roomSpecific_slice = createSlice({
    name: "roomSpecificReducer",
    initialState: initialCompetitionState,
    reducers: {
        // // RETRIEVE ALL ROOM REDUCER
        retrieveRoomDevices: (state, action) => {
            state.isLoading = true;
            state.retrieveRoomDevicesResponse = null;
            state.retrieveRoomDevicesError = null;
        },
        retrieveRoomDevicesSuccess: (state, action) => {
            state.isLoading = false;
            state.retrieveRoomDevicesResponse = action.payload;
        },
        retrieveRoomDevicesFailure: (state, action) => {
            state.isLoading = false;
            state.retrieveRoomDevicesError = action.payload;
        },


        // // RETRIEVE ALL ROOM REDUCER
        updateRoomDevicesTargetTemp: (state, action) => {
            state.isLoading = true;
            state.updateRoomDevicesTargetTempResponse = null;
            state.updateRoomDevicesTargetTempError = null;
        },
        updateRoomDevicesTargetTempSuccess: (state, action) => {
            state.isLoading = false;
            state.updateRoomDevicesTargetTempResponse = action.payload;
        },
        updateRoomDevicesTargetTempFailure: (state, action) => {
            state.isLoading = false;
            state.updateRoomDevicesTargetTempError = action.payload;
        },

    },


    extraReducers: {

    },
});
