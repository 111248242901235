import AxiosConfig from 'src/helper/config/axiosConfig';
import { baseApiUrl } from 'src/helper/config/baseURLconfig';
import { apartmentList_slice } from "./apartmentList_slice";
const { actions } = apartmentList_slice;



// // RETRIEVE ALL APARTMENT ACTIONS
export const retrieveApartmentListActions = (model) => async (dispatch) => {
    try {
        // dispatch starting action
        dispatch(actions.retrieveApartmentList());

        // const response = await fetch(`${baseApiUrl}/apartments`, {
        //     method: 'GET',
        //     headers: {
        //         'Content-Type': 'application/json',
        //         // body: JSON.stringify(),
        //     },
        // })

        // using interceptor for axios
        const response = await AxiosConfig().get(`/apartments`)

        // dispatch success action
        dispatch(actions.retrieveApartmentListSuccess(response.data));

    } catch (error) {
        // dispatch failure action
        dispatch(actions.retrieveApartmentListFailure({ error }));
    }
}
