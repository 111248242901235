import { combineReducers } from "redux";
import { globalStateSlice } from "./globalState_redux/globalState_slice";
import { authSlice } from "./auth_redux/auth_slice";
import { apartmentList_slice } from "./apartmentList_redux/apartmentList_slice"
import { roomList_slice } from "./roomList_redux/roomList_slice";
import { roomSpecific_slice } from "./roomSpecific_redux/roomSpecific_slice";

//Combined all reducer to root reducer
export const rootReducer = combineReducers({
  globalStateReducer: globalStateSlice.reducer,
  authReducer: authSlice.reducer,
  apartmentListReducer: apartmentList_slice.reducer,
  roomListReducer: roomList_slice.reducer,
  roomSpecificReducer: roomSpecific_slice.reducer,
});
