import React, { Fragment, useState, useEffect } from 'react'
import Cookies from 'js-cookie';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { handleChangLanguage } from 'src/utils/locales/languageHelper'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { sideNavbarMenueShowActions } from 'src/redux/globalState_redux/globalState_action'
// import { darkModeHelperFunction } from 'src/helper/functions/darkModeHelper';
import avatar1 from "src/assets/img/sample/avatar/avatar1.jpg"
import { useIsAuthenticated, useMsal } from "@azure/msal-react";




function SideNavbarMenue() {
    // // ----------Localization hooks & Router Hooks-------------
    const { t, i18n } = useTranslation('common');



    // // ----------redux store useDispatch & useSelector --------------------
    const dispatch = useDispatch();
    const { instance, accounts } = useMsal();

    // // 2nd way to get data ==> by using useSelector
    const reducerState = useSelector((state) => (state));
    let globalStateReducer = reducerState.globalStateReducer
    let isSideBarShow = globalStateReducer.isSideNavbarMenuShow

    const [selectedLanguage, setSelectedLanguage] = useState("")


    useEffect(() => {
        if (i18next.language) {
            setSelectedLanguage(i18next.language)
        }
        else {
            setSelectedLanguage("en")
        }
    }, [i18next.language])

    // useEffect(() => {
    //     darkModeHelperFunction()
    // }, [])

    // // handler function
    const handleSetLanguageChange = (lang) => {
        handleChangLanguage(lang)
        setSelectedLanguage(lang)
    }

    const handleSidebarShow = () => {
        dispatch(sideNavbarMenueShowActions())
    }

    const handleLogout = () => {
        Cookies.remove('levikairaAuth');
        instance.logoutRedirect();
    }


    const sideBarContent = () => {
        return (
            <Fragment>
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-body p-0">
                            {/* <!-- profile box --> */}
                            <div className="profileBox pt-2 pb-2">
                                <div className="image-wrapper">
                                    <ion-icon name="person-circle-outline" style={{ fontSize: "30px" }}></ion-icon>
                                </div>
                                <div className="in">
                                    <strong>{accounts[0] && accounts[0].name}</strong>
                                </div>
                                <Link to="#" className="btn btn-link btn-icon sidebar-close" data-bs-dismiss="modal" onClick={() => handleSidebarShow()}>
                                    <ion-icon name="close-outline"></ion-icon>
                                </Link>
                            </div>


                            {/* <!-- menu --> */}
                            <div className="listview-title mt-1">{t("Menu")}</div>
                            <ul className="listview flush transparent no-line image-listview" >
                                {/* <li onClick={() => handleSidebarShow()}>
                                    <Link to="/dashboard" className="item">
                                        <div className="icon-box bg-primary">
                                            <ion-icon name="home-outline"></ion-icon>
                                        </div>
                                        <div className="in">
                                            {t("Overview")}
                                            <span className="badge badge-primary">10</span>
                                        </div>
                                    </Link>
                                </li> */}
                                <li onClick={() => handleSidebarShow()}>
                                    <Link to="/apartmentlist" className="item">
                                        <div className="icon-box bg-primary">
                                            <ion-icon name="business-outline"></ion-icon>
                                        </div>
                                        <div className="in">
                                            {t("Apartments")}
                                        </div>
                                    </Link>
                                </li>
                            </ul>
                            {/* <!-- * menu --> */}

                            {/* <!-- others --> */}
                            {/* <div className="listview-title mt-1">Others</div>
                            <ul className="listview flush transparent no-line image-listview">
                                <li onClick={() => handleSidebarShow()}>
                                    <Link to="/about" className="item">
                                        <div className="icon-box bg-primary">
                                            <ion-icon name="pie-chart-outline"></ion-icon>

                                        </div>
                                        <div className="in">
                                            {t("About Us")}
                                        </div>
                                    </Link>
                                </li>
                                <li onClick={() => handleSidebarShow()}>
                                    <Link to="/settings" className="item">
                                        <div className="icon-box bg-primary">
                                            <ion-icon name="settings-outline"></ion-icon>
                                        </div>
                                        <div className="in">
                                            {t("Settings")}
                                        </div>
                                    </Link>
                                </li>
                            </ul> */}
                            {/* <!-- * others --> */}
                            <div className="listview-title mt-1">{t("General")}</div>
                            <ul className="listview flush transparent no-line image-listview ">
                                <li>
                                    <div className="item sideNavbar__dropdownMain" >
                                        <div className="icon-box bg-primary">
                                            <ion-icon name="language-outline"></ion-icon>
                                        </div>
                                        <div className="sideNavbar__dropdownTitle">{t("Language")} </div>
                                        <select name='uiTech' value={selectedLanguage} onChange={e => handleSetLanguageChange(e.target.value)} className="sideNavbar__dropdownSelect">
                                            <option value="en" className="sideNavbar__dropdownOption" > English </option>
                                            <option value="fi" className="sideNavbar__dropdownOption" > Suomi </option>
                                        </select>
                                    </div>
                                </li>
                                {/* <li>
                                    <div className="item">
                                        <div className="icon-box bg-primary">
                                            <ion-icon name="moon-outline"></ion-icon>
                                        </div>
                                        <div className="in">
                                            <div>Dark Mode</div>
                                            <div className="form-check form-switch  ms-2">
                                                <input className="form-check-input dark-mode-switch" type="checkbox" id="darkmodeSwitch" />
                                                <label className="form-check-label" htmlFor="darkmodeSwitch"></label>
                                            </div>
                                        </div>
                                    </div>
                                </li> */}
                                <li>
                                    <Link to="/" className="item" onClick={() => handleLogout()}>
                                        <div className="icon-box bg-primary">
                                            <ion-icon name="log-out-outline"></ion-icon>
                                        </div>
                                        <div className="in">
                                            {t("Log Out")}
                                        </div>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }

    return (
        <Fragment>
            {/* <!-- App Sidebar --> */}

            {
                isSideBarShow ? (
                    <div className="modal fade panelbox panelbox-left show sideNavbar__container" id="sidebarPanel" tabIndex="-1" style={{ display: 'block' }} aria-modal="true" role="dialog">
                        {sideBarContent()}
                    </div>
                ) : (
                    <div className="modal fade panelbox panelbox-left" id="sidebarPanel" tabIndex="-1" role="dialog" >
                        {sideBarContent()}
                    </div>

                )

            }

            {/* <!-- * App Sidebar --> */}

        </Fragment>
    )
}

export default SideNavbarMenue
