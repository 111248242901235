import React from 'react'

function Notification() {
    return (
        <div>
              <div id="appCapsule">

<div className="section full">

    <ul className="listview image-listview flush">
        {/* <li className="active">
            <a href="app-notification-detail.html" className="item">
                <div className="icon-box bg-primary">
                    <ion-icon name="arrow-down-outline"></ion-icon>
                </div>
                <div className="in">
                    <div>
                        <div className="mb-05"><strong>Payment Received</strong></div>
                        <div className="text-small mb-05">John sent you <b>$ 50</b></div>
                        <div className="text-xsmall">5/3/2020 10:30 AM</div>
                    </div>
                    <span className="badge badge-primary badge-empty"></span>
                </div>
            </a>
        </li>
        <li className="active">
            <a href="app-notification-detail.html" className="item">
                <div className="icon-box bg-success">
                    <ion-icon name="arrow-forward-outline"></ion-icon>
                </div>
                <div className="in">
                    <div>
                        <div className="mb-05"><strong>Money has been sent</strong></div>
                        <div className="text-small mb-05">The money you sent to John was successfully sent.</div>
                        <div className="text-xsmall">5/3/2020 10:30 AM</div>
                    </div>
                    <span className="badge badge-primary">3</span>
                </div>
            </a>
        </li> */}
        <li>
            <a href="app-notification-detail.html" className="item">
                <div className="icon-box bg-danger">
                    <ion-icon name="chatbubble-outline"></ion-icon>
                </div>
                <div className="in">
                    <div>
                        <div className="mb-05"><strong>Thermostat not working</strong></div>
                        <div className="text-small mb-05">levikaira 1</div>
                        <div className="text-xsmall">5/8/2021 10:30 AM</div>
                    </div>
                </div>
            </a>
        </li>
        <li>
            <a href="app-notification-detail.html" className="item">
                <div className="icon-box bg-warning">
                    <ion-icon name="chatbubble-outline"></ion-icon>
                </div>
                <div className="in">
                    <div>
                        <div className="mb-05"><strong>Temperature fluctuation warning</strong></div>
                        <div className="text-small mb-05">levikaira 2</div>
                        <div className="text-xsmall">5/8/2021 10:40 AM</div>
                    </div>
                </div>
            </a>
        </li>
        {/* <li>
            <a href="app-notification-detail.html" className="item">
                <div className="icon-box bg-primary">
                    <ion-icon name="arrow-down-outline"></ion-icon>
                </div>
                <div className="in">
                    <div>
                        <div className="mb-05"><strong>Payment Received</strong></div>
                        <div className="text-small mb-05">John sent you <b>$ 50</b></div>
                        <div className="text-xsmall">5/3/2020 10:30 AM</div>
                    </div>
                </div>
            </a>
        </li>
        <li>
            <a href="app-notification-detail.html" className="item">
                <div className="icon-box bg-success">
                    <ion-icon name="arrow-forward-outline"></ion-icon>
                </div>
                <div className="in">
                    <div>
                        <div className="mb-05"><strong>Money has been sent</strong></div>
                        <div className="text-small mb-05">The money you sent to John was successfully sent.</div>
                        <div className="text-xsmall">5/3/2020 10:30 AM</div>
                    </div>
                </div>
            </a>
        </li> */}
        {/* <li>
            <a href="app-notification-detail.html" className="item">
                <div className="icon-box bg-danger">
                    <ion-icon name="key-outline"></ion-icon>
                </div>
                <div className="in">
                    <div>
                        <div className="mb-05"><strong>Password changed</strong></div>
                        <div className="text-small mb-05">Your password has been changed</div>
                        <div className="text-xsmall">5/3/2020 10:30 AM</div>
                    </div>
                </div>
            </a>
        </li> */}
        <li>
            <a href="app-notification-detail.html" className="item">
                <div className="icon-box bg-warning">
                    <ion-icon name="chatbubble-outline"></ion-icon>
                </div>
                <div className="in">
                    <div>
                        <div className="mb-05"><strong>Outage warning</strong></div>
                        <div className="text-small mb-05">levikaira 4</div>
                        <div className="text-xsmall">5/8/2021 11:30 AM</div>
                    </div>
                </div>
            </a>
        </li>
        <li>
            <a href="app-notification-detail.html" className="item">
                <div className="icon-box bg-warning">
                    <ion-icon name="chatbubble-outline"></ion-icon>
                </div>
                <div className="in">
                    <div>
                        <div className="mb-05"><strong>Temperature fluctuation warning</strong></div>
                        <div className="text-small mb-05">levikaira 7</div>
                        <div className="text-xsmall">5/8/2021 11:40 AM</div>
                    </div>
                </div>
            </a>
        </li>
    </ul>

</div>

</div>

        </div>
    )
}

export default Notification
