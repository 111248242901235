import React, { Fragment, useState, useEffect } from 'react'
import { usePrevious } from 'src/helper/customHooks/customHooks';
import { useHistory, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { retrieveApartmentListActions } from 'src/redux/apartmentList_redux/apartmentList_action';
import { Container, Row, Col } from 'react-bootstrap';
import alertSymbol from "src/assets/icons/alertSymbol.svg"

// Msal imports


import apartment_jpg from "src/assets/icons/log_cabin_icon.png"




function ApartmentList() {
    // // ----------Localization hooks & Router Hooks-------------
    const { t, i18n } = useTranslation('common');
    const history = useHistory()



    // // ----------Props & context & ref ------------------------------



    // // ----------redux store useDispatch & useSelector --------------------
    const dispatch = useDispatch()
    // // 2nd way to get data ==> by using useSelector
    const reducerState = useSelector((state) => (state));
    let apartmentListReducer = reducerState.apartmentListReducer


    // // ----------hooks useState--------------------------------------------------
    const [apartmentList, setApartmentList] = useState()


    // // ----------hooks useEffect--------------------------------------------------
    useEffect(() => {
        dispatch(retrieveApartmentListActions())
    }, [])


    // // ***To check responce/error after success/error action from reducer***
    const { isLoading, retrieveApartmentListResponse, retrieveApartmentListError } = apartmentListReducer
    const prevPropsState = usePrevious({ retrieveApartmentListResponse, retrieveApartmentListError }) // custom hook to get previous props & state
    useEffect(() => {
        if (prevPropsState) {
            if (prevPropsState.retrieveApartmentListResponse !== retrieveApartmentListResponse && retrieveApartmentListResponse) {
                setApartmentList(retrieveApartmentListResponse)
                // setTimeout(() => {
                //     toast.success("Apartment Retrieve successfully")
                // }, 500);
            }
            else if (prevPropsState.retrieveApartmentListError !== retrieveApartmentListError && retrieveApartmentListError) {
                setTimeout(() => {
                    toast.error("Something wrong happened..")
                }, 500);
            }
        }
    }, [apartmentListReducer])


    // // ----------handler functions--------------------------------------------------
    const handleRetrieveRoomList = (data) => {
        history.push({
            pathname: `/apartment/${data.id}`,
            state: { data: data }
        })
    }




    return (
        <Fragment>
            <div className="section mt-4 apartmentlist">
                <div className="section mt-2 apartmentlist__heading">
                    <h2> {t("Apartment List")}</h2>
                </div>
                <div className="transactions apartmentlist__container">
                    {
                        // apartment item
                        apartmentList && apartmentList[0] && apartmentList.map((item, index) => (
                            <div className="item apartmentlist__item " onClick={() => handleRetrieveRoomList(item)} key={index} >
                                <Container>
                                    <Row>
                                        <Col xs="2"><img src={apartment_jpg} alt="img" className="image-block imaged w32" /></Col>
                                        <Col xs="8"><strong>{item.name}</strong></Col>
                                        <Col xs="2">{item.alert === true && <span><img src={alertSymbol} alt="alertSymbol" style={{ width: 32, height: 32 }} /></span>}</Col>
                                    </Row>
                                </Container>
                                {
                                <div className="right">
                                    { (item.reserved) ? 
                                        <div className="price text-danger">Varattu</div>
                                        :
                                        <div className="price text-success">Vapaa</div>
                                    }
                                </div> 
                                }
                            </div>
                        ))
                    }
                </div>
            </div>
        </Fragment>
    )
}

export default ApartmentList
