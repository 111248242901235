import AxiosConfig from 'src/helper/config/axiosConfig';
import { baseApiUrl } from 'src/helper/config/baseURLconfig';
import { roomList_slice } from "./roomList_slice";
const { actions } = roomList_slice;


// // RETRIEVE ALL ROOM ACTIONS
export const retrieveRoomListActions = (model) => async (dispatch) => {
    try {
        // dispatch starting action
        dispatch(actions.retrieveRoomList());

        // // using interceptor for axios
        const response = await AxiosConfig().get(`/apartments/${model}/rooms`)  // model == apartmentId

        // dispatch success action
        dispatch(actions.retrieveRoomListSuccess(response.data));

    } catch (error) {
        // dispatch failure action
        dispatch(actions.retrieveRoomListFailure({ error }));
    }
}

