import AxiosConfig from 'src/helper/config/axiosConfig';
import { baseApiUrl } from 'src/helper/config/baseURLconfig';
import { roomSpecific_slice } from "./roomSpecific_slice";
const { actions } = roomSpecific_slice;




// // RETRIEVE ROOM DEVICES ACTIONS
export const retrieveRoomDevicesActions = (model) => async (dispatch) => {
    try {
        let apartmentId = model.apartmentId
        let roomId = model.roomId

        // dispatch starting action
        dispatch(actions.retrieveRoomDevices());

        // // using interceptor for axios
        const response = await AxiosConfig().get(`/apartments/${apartmentId}/rooms/${roomId}/devices`)

        // dispatch success action
        dispatch(actions.retrieveRoomDevicesSuccess(response.data.sort((a, b) => a.name.localeCompare(b.name))));

    } catch (error) {
        // dispatch failure action
        dispatch(actions.retrieveRoomDevicesFailure({ error }));
    }
}


// // UPDATE ROOM DEVICES ACTIONS
export const updateRoomDevicesTargetTempActions = (model) => async (dispatch) => {
    try {
        const { apartmentId, roomId, deviceId, newTargetTemperature } = model
        // dispatch starting action
        dispatch(actions.updateRoomDevicesTargetTemp());
        // // using interceptor for axios
        const response = await AxiosConfig().post(`/apartments/${apartmentId}/rooms/${roomId}/devices/${deviceId}/change-target-temperature?newTargetTemperature=${newTargetTemperature}`)
        // dispatch success action
        dispatch(actions.updateRoomDevicesTargetTempSuccess(response));

    } catch (error) {
        // dispatch failure action
        dispatch(actions.updateRoomDevicesTargetTempFailure({ error }));
    }
}