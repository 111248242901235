import React, { Fragment, useState, useEffect } from 'react'
import { usePrevious } from 'src/helper/customHooks/customHooks';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux'
import { Link, useLocation } from "react-router-dom";
import { sideNavbarMenueShowActions } from 'src/redux/globalState_redux/globalState_action';



function BottomNavbarMenu() {
    // // ----------Localization hooks & Router Hooks-------------
    const { t, i18n } = useTranslation('common');


    const location = useLocation();

    // // ----------redux store useDispatch & useSelector --------------------
    const dispatch = useDispatch()
    // // 2nd way to get data ==> by using useSelector
    const reducerState = useSelector((state) => (state));
    let networkStatus = reducerState.globalStateReducer.networkStatus



    const [activeBottomTabItem, setActiveBottomTabItem] = useState("")
    const [showOnlineMessage, setShowOnlineMessage] = useState(false)


    const prevPropsState = usePrevious({ networkStatus }) // custom hook to get previous props & state
    useEffect(() => {
        if (prevPropsState) {
            if (prevPropsState.networkStatus !== networkStatus && networkStatus === "online") {
                setShowOnlineMessage(true)
                setTimeout(() => {
                    setShowOnlineMessage(false)
                }, 5000);
            }
        }
    }, [prevPropsState])



    useEffect(() => {
        if (location.pathname === "/dashboard") {
            setActiveBottomTabItem("dashboard")
        }
        else if (location.pathname === "/apartmentlist") {
            setActiveBottomTabItem("apartmentlist")
        }
        else if (location.pathname === "/settings") {
            setActiveBottomTabItem("settings")
        }
        else {
            setActiveBottomTabItem("")
        }
        return () => {

        }
    }, [location])



    return (
        <Fragment>
            <div className="bottom__main" onClick={() => dispatch(sideNavbarMenueShowActions(false))}>
                {/* <!-- App Bottom Menu --> */}
                <div className="appBottomMenu bottom__container">
                    {/* <Link to="/dashboard" className={activeBottomTabItem === "dashboard" ? 'item active' : "item"}>
                        <div className="col bottom__fontSize">
                            <ion-icon name="home-outline" ></ion-icon>
                            <strong>{t("Dashboard")}</strong>
                        </div>
                    </Link> */}
                    <Link to="/apartmentlist" className={activeBottomTabItem === "apartmentlist" ? 'item active' : "item"}>
                        <div className="col bottom__fontSize">
                            <ion-icon name="business-outline"></ion-icon>
                            <strong>{t("Apartments")}</strong>
                        </div>
                    </Link>
                    {/* <Link to="/settings" className={activeBottomTabItem === "settings" ? 'item active' : "item"}>
                        <div className="col bottom__fontSize">
                            <ion-icon name="settings-outline"></ion-icon>
                            <strong>{t("Settings")}</strong>
                        </div>
                    </Link> */}
                </div>
                {
                    networkStatus === "offline" ?
                        (<div className="bottom__offlineMessage">{t("You are offline")}</div>)
                        :
                        showOnlineMessage === true ?
                            (<div className="bottom__onlineMessage">{t("Back to online...")}</div>)
                            : null
                }

            </div>
            {/* <!-- * App Bottom Menu --> */}
        </Fragment>
    )
}

export default BottomNavbarMenu
