import React, { Fragment, useState, useEffect } from 'react'
import { usePrevious } from 'src/helper/customHooks/customHooks';
import { useParams } from 'react-router';
import { useHistory, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { retrieveRoomListActions } from 'src/redux//roomList_redux/roomList_action';



import alertSymbol from "src/assets/icons/alertSymbol.svg"

// import bedroom from "src/assets/icons/bedroom.png"


function RoomList() {

    // // ----------Localization hooks & Router Hooks-------------
    const { t, i18n } = useTranslation('common');
    const history = useHistory()
    const params = useParams();



    // // ----------Props & context & ref ------------------------------



    // // ----------redux store useDispatch & useSelector --------------------
    const dispatch = useDispatch()
    // // 2nd way to get data ==> by using useSelector
    const reducerState = useSelector((state) => (state));
    let roomListReducer = reducerState.roomListReducer



    // // ----------hooks useState--------------------------------------------------
    const [roomList, setRoomList] = useState(null)



    // // ----------hooks useEffect--------------------------------------------------
    useEffect(() => {
        let path = window.location.pathname;
        let str = path.split("/");
        dispatch(retrieveRoomListActions(str[2]))
    }, [])


    // // ***To check responce/error after success/error action from reducer***
    const { isLoading, retrieveRoomListResponse, retrieveRoomListError } = roomListReducer
    const prevPropsState = usePrevious({ retrieveRoomListResponse, retrieveRoomListError }) // custom hook to get previous props & state
    useEffect(() => {
        if (prevPropsState) {
            if (prevPropsState.retrieveRoomListResponse !== retrieveRoomListResponse && retrieveRoomListResponse) {
                setRoomList(retrieveRoomListResponse)
                // setTimeout(() => {
                //     toast.success("Room Retrieve successfully")
                // }, 500);
            }
            else if (prevPropsState.retrieveRoomListError !== retrieveRoomListError && retrieveRoomListError) {
                setTimeout(() => {
                    toast.error("Something wrong happened..")
                }, 500);
            }
        }
    }, [roomListReducer])

    // // ----------handler functions--------------------------------------------------
    const handleRetrieveRoomDetail = (roomData) => {
        history.push({
            pathname: `/roomspecific/${params.apartmentId}/${roomData.id}`,
            state: { roomData: roomData }
        })
    }

    return (
        <Fragment>
            <div className="section mt-4 roomlist">
                <div className="section mt-2 roomlist__heading">
                    <h2>{history.location.state.data.name}</h2>
                </div>
                <div className="transactions roomlist__container">
                    {
                        // apartment item
                        roomList && roomList[0] ? roomList.map((item, index) => (
                            <div className="item roomlist__item " onClick={() => handleRetrieveRoomDetail(item)} key={index} >
                                <div className="detail">
                                    {/* <img src={apartment_jpg} alt="img" className="image-block imaged w48" /> */}
                                    <div>
                                        <strong>{item.name}</strong>
                                        {/* <p>{t("Temperature")} : {item.roomTemperature} &#8451; / {item.targetTemperature} &#8451;</p> */}
                                        <div className="room__temperatureContainer">
                                            <div className="room__temperatureCard">
                                                {/* <!-- card block --> */}
                                                <div className="card-block bg-dark room__block_small">
                                                    <span className="room__temperatureTitle">{t("Target Temperature Short")}</span>
                                                    <div className="title room__temperatureValue">{item.targetTemperature} &#8451;</div>
                                                </div>
                                                {/* <!-- * card block --> */}
                                            </div>

                                            <div className="room__temperatureCard">
                                                {/* <!-- card block --> */}
                                                <div className={"card-block room__block_small " + (item.heatRegulatedByFloorTemp ? "bg-tertiary" : "bg-secondary")}>
                                                    <span className="room__temperatureTitle">{t("Room Temperature Short")}</span>
                                                    <div className="title room__temperatureValue">{item.roomTemperature} &#8451;</div>
                                                </div>
                                                {/* <!-- * card block --> */}
                                            </div>

                                            { !!item.floorTemperature && 
                                                <div className="room__temperatureCard">
                                                    {/* <!-- card block --> */}
                                                    <div className={"card-block room__block_small " + (item.heatRegulatedByFloorTemp ? "bg-secondary" : "bg-tertiary")}>
                                                        <span className="room__temperatureTitle" >{t("Floor Temperature Short")}</span>
                                                        <div className="title room__temperatureValue">{item.floorTemperature} &#8451;</div>
                                                    </div>
                                                    {/* <!-- * card block --> */}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="right">
                                    {item.alert ? <div className="price text-danger">
                                        <img src={alertSymbol} alt="alertSymbol" style={{ width: 60, height: 60, marginTop: 25 }} />
                                    </div> : null}
                                </div>
                            </div>
                        ))
                            :
                            roomList && <div className="alert alert-info mt-5" role="alert">No Rooms are available</div>
                    }
                </div>
            </div>
        </Fragment>
    )
}

export default RoomList
