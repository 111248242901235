import React, { Fragment, useState, useEffect } from 'react'
import { usePrevious } from 'src/helper/customHooks/customHooks';
import { useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { retrieveRoomDevicesActions, updateRoomDevicesTargetTempActions } from 'src/redux/roomSpecific_redux/romSpecific_action';
import { useHistory, Link } from 'react-router-dom';
import { Accordion, Card, Button, Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Moment from 'react-moment';
import moment from 'moment';
import alertSymbol from "src/assets/icons/alertSymbol.svg"


function RoomSpecific() {
    // // ----------Localization hooks & Router Hooks-------------
    const { t, i18n } = useTranslation('common');
    const history = useHistory()



    // // ----------Props & context & ref ------------------------------
    const params = useParams();
    let retrieveRoomDevicesModel = {
        apartmentId: params.apartmentId,
        roomId: params.roomId
    }


    // // ----------redux store useDispatch & useSelector --------------------
    const dispatch = useDispatch()
    const reducerState = useSelector((state) => (state));
    let roomSpecificReducer = reducerState.roomSpecificReducer



    // // ----------hooks useState--------------------------------------------------
    const [state, setState] = useState({
        initialRoomInfo: null,
        currentRoomInfo: null,
        timeOutVariable: null,
    })
    const [roomDevices, setRoomDevices] = useState(null)



    // // ----------hooks useEffect--------------------------------------------------
    useEffect(() => {
        setState({
            ...state,
            initialRoomInfo: JSON.parse(JSON.stringify(history.location.state.roomData)),
            currentRoomInfo: JSON.parse(JSON.stringify(history.location.state.roomData)),
        })

        dispatch(retrieveRoomDevicesActions(retrieveRoomDevicesModel))
    }, [])

    // // ***To check responce/error after success/error action from reducer***
    const { retrieveRoomDevicesResponse, retrieveRoomDevicesError, updateRoomDevicesTargetTempResponse, updateRoomDevicesTargetTempError } = roomSpecificReducer
    const prevPropsState = usePrevious({ retrieveRoomDevicesResponse, retrieveRoomDevicesError, updateRoomDevicesTargetTempResponse, updateRoomDevicesTargetTempError }) // custom hook to get previous props & state
    useEffect(() => {
        if (prevPropsState) {
            // // RETRIEVE ROOM DEVICES
            if (retrieveRoomDevicesResponse && prevPropsState.retrieveRoomDevicesResponse !== retrieveRoomDevicesResponse) {
                setRoomDevices(retrieveRoomDevicesResponse);
                updateRoomState(retrieveRoomDevicesResponse);
            }
            else if (retrieveRoomDevicesError && prevPropsState.retrieveRoomDevicesError !== retrieveRoomDevicesError) {
                setTimeout(() => {
                    toast.error("Something wrong happened..")
                }, 500);
            }

            // // UPDATE ROOM DEVICES
            if (updateRoomDevicesTargetTempResponse && prevPropsState.updateRoomDevicesTargetTempResponse !== updateRoomDevicesTargetTempResponse) {
                updateRoomState(updateRoomDevicesTargetTempResponse.data);
                dispatch(retrieveRoomDevicesActions(retrieveRoomDevicesModel));
                setTimeout(() => {
                    toast.success(t("Room Temperature update successfully"));
                }, 500);
            }
            else if (updateRoomDevicesTargetTempError && prevPropsState.updateRoomDevicesTargetTempError !== updateRoomDevicesTargetTempError) {
                setState({
                    ...state,
                    currentRoomInfo: { ...state.initialRoomInfo },
                })
                setTimeout(() => {
                    toast.error("Something wrong happened...");
                }, 500);
            }
        }
    }, [roomSpecificReducer])

    // // ----------handler functions--------------------------------------------------
    const updateRoomState = (devices) => {
        let newUpdateRoomDevicesTargetTempResponse = getThermostat(devices);
        setState({
            ...state,
            initialRoomInfo: {
                ...state.initialRoomInfo,
                floorTemperature: newUpdateRoomDevicesTargetTempResponse.floorTemperature,
                roomTemperature: newUpdateRoomDevicesTargetTempResponse.roomTemperature,
                targetTemperature: newUpdateRoomDevicesTargetTempResponse.targetTemperature,
            },
            currentRoomInfo: {
                ...state.currentRoomInfo,
                floorTemperature: newUpdateRoomDevicesTargetTempResponse.floorTemperature,
                roomTemperature: newUpdateRoomDevicesTargetTempResponse.roomTemperature,
                targetTemperature: newUpdateRoomDevicesTargetTempResponse.targetTemperature,
            },
        })
    }

    const refreshData = () => {
        dispatch(retrieveRoomDevicesActions(retrieveRoomDevicesModel));
    }

    const handleChangeTargetTemperature = (changeTemp) => {
        clearTimeout(state.timeOutVariable);
        state.currentRoomInfo.targetTemperature = state.currentRoomInfo.targetTemperature + changeTemp;
        setState(prevState => ({ ...prevState }));
        state.timeOutVariable = setTimeout(() => {
            let modelObj = {
                apartmentId: state.currentRoomInfo.apartmentId,
                roomId: state.currentRoomInfo.id,
                deviceId: roomDevices && getThermostat(roomDevices).id,
                newTargetTemperature: state.currentRoomInfo.targetTemperature,
            }
            dispatch(updateRoomDevicesTargetTempActions(modelObj));
        }, 500);
    }

    const getThermostat = (devices) => {
        return devices.find( d => d.deviceTypeInfo.deviceType === "Thermostat");
    }

    const getFormatedDateValue = (isoDate) => {
        let options = {
            hour12: "true",
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
        };

        let date = new Date(isoDate);

        let formatedDate = date.toLocaleString('fi-FI', options); // dd.mm.yyyy
        return formatedDate;
    }



    return (
        <Fragment>
            {/* <!-- my cards --> */}
            {state.currentRoomInfo &&
                <div className="room">
                    <div className="room__heading">
                        <h2>{state.currentRoomInfo.name}</h2>
                    </div>
                    <div className="room__temperatureContainer">
                        <div className="room__temperatureCard">
                            {/* <!-- card block --> */}
                            <div className="card-block bg-dark room__block">
                                <span className="room__temperatureTitle">{t("Target Temperature")}</span>
                                <p className="title room__temperatureValue">{state.currentRoomInfo.targetTemperature} &#8451;</p>
                            </div>
                            {/* <!-- * card block --> */}
                        </div>

                        <div className="room__temperatureCard">
                            {/* <!-- card block --> */}
                            <div className={"card-block room__block " + (state.currentRoomInfo.heatRegulatedByFloorTemp ? "bg-tertiary" : "bg-secondary")}>
                                <span className="room__temperatureTitle">{t("Room Temperature")}</span>
                                <p className="title room__temperatureValue">{state.currentRoomInfo.roomTemperature} &#8451;</p>
                            </div>
                            {/* <!-- * card block --> */}
                        </div>

                        {state.currentRoomInfo.floorTemperature &&
                            <div className="room__temperatureCard">
                                {/* <!-- card block --> */}
                                <div className={"card-block room__block " + (state.currentRoomInfo.heatRegulatedByFloorTemp ? "bg-secondary" : "bg-tertiary")}>
                                    <span className="room__temperatureTitle" >{t("Floor Temperature")}</span>
                                    <p className="title room__temperatureValue">{state.currentRoomInfo.floorTemperature} &#8451;</p>
                                </div>
                                {/* <!-- * card block --> */}
                            </div>
                        }
                    </div>
                    {/* <!-- * my cards --> */}



                    <div className="mt-1 ml-5">
                        <button type="button" className="btn btn-primary btn-block btn-lg room__deviceBtn" onClick={() => handleChangeTargetTemperature(-1)}><FontAwesomeIcon icon="minus" color="white" size="lg"/></button>
                        <button type="button" className="btn btn-primary btn-block btn-lg room__deviceBtn ml-3 mt-0" onClick={() => handleChangeTargetTemperature(1)}><FontAwesomeIcon icon="plus" color="white" size="lg"/></button>
                        <button type="button" className="btn btn-light btn-block btn-lg room__deviceBtn ml-3 mt-0" onClick={() => refreshData()}><FontAwesomeIcon icon="sync-alt" color="black" size="lg"/></button>
                    </div>

                <div className="section mt-2 mb-2 ">
                    <div className="section-title">{t("Devices")}</div>

                    <div className="transactions">
                    {
                        roomDevices && roomDevices.map((item, index) => (
                            <div className="item" key={index} >
                                {/* <img src={apartment_jpg} alt="img" className="image-block imaged w48" /> */}
                                <Container>
                                    <Row>
                                        <Col xs="8">
                                            <strong>{item.name}</strong>
                                            <br />
                                            <span className={moment(item.lastCommunication).isAfter(moment().subtract(24, 'hours')) ? "room__heating" : "room__heatingProblem"} ><small>Yhteys <Moment fromNow ago>{item.lastCommunication}</Moment> sitten</small></span>
                                        </Col>
                                        <Col xs="2" className="text-align-center">
                                            {item.deviceTypeInfo.canHeat && item.heating && <FontAwesomeIcon icon="burn" color="red" size="lg"/>}
                                            {item.deviceTypeInfo.canHeat && !item.heating && <FontAwesomeIcon icon="burn" color="lightgrey" size="lg"/>}
                                            {!item.deviceTypeInfo.canHeat && <FontAwesomeIcon icon="thermometer-half" color="#2f5c85" size="lg"/>}
                                            <br />
                                            {item.deviceTypeInfo.isBatteryOperated && <FontAwesomeIcon icon="battery-full" color="#2f5c85" size="lg"/>}
                                            {!item.deviceTypeInfo.isBatteryOperated && <FontAwesomeIcon icon="plug" color="#2f5c85" size="lg"/>}
                                        </Col>
                                        <Col xs="2">{!moment(item.lastCommunication).isAfter(moment().subtract(24, 'hours')) && <span><img src={alertSymbol} alt="alertSymbol" style={{ width: 36, height: 36 }} /></span>}</Col>
                                    </Row>
                                </Container>
                            </div>
                        ))
                    }
                    </div>
                </div>
                <Accordion defaultActiveKey="0" style={{ margin: 10, marginTop: 20, marginLeft: "2.1%", width: "95%" }}>
                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link" eventKey="1">
								<span type="button" className="btn btn-primary">{t("Details")}</span>
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="1" style={{ padding: 0, paddingBottom: 20, backgroundColor: "#f8f9fa" }}>
                            <Card.Body style={{ padding: 0, backgroundColor: "#f8f9fa" }}>
                                <div className="mt-5 room__roomLog mr-2">
                                    {
                                        roomDevices && roomDevices.filter(opt => opt.deviceTypeInfo.canHeat).map((item, index) => (
                                            <div key={index} className="mb-5">
                                                <div style={{ fontWeight: "bold" }}>
                                                    {item.name}
                                                </div>
                                                <table className="table table-striped room__table">
                                                    <tbody>
                                                    {item.eventLog.map((eventLog, i) => (
                                                        <tr key={i}>
                                                            <td>
                                                                <div key={i}><Moment calendar>{eventLog.timestamp}</Moment></div>
                                                            </td>
                                                            <td>
                                                                {eventLog.message}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        ))}
                                </div>
                                {roomDevices && roomDevices.length === 0 && <p style={{ textAlign: 'center', marginTop: -30 }}>No record available.</p>}
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>

                </div>
			}
        </Fragment>
    )
}

export default RoomSpecific
