import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'




function Dashboard() {
    // // ----------Localization hooks & Router Hooks-------------
    const { t, i18n } = useTranslation('common');



    return (
        <Fragment>

            {/* <!-- Stats --> */}
            <div className="section dashboard__container">
                {/* <div className="row mt-2"> */}
                <div className="dashboard__item">
                    <div className="stat-box">
                        <div className="title">{t("Number of Apartment")}</div>
                        <div className="value text-success"> 30</div>
                    </div>
                </div>
                <div className="dashboard__item" >
                    <div className="stat-box">
                        <div className="title">{t("Number of Rooms")}</div>
                        <div className="value text-danger">600</div>
                    </div>
                </div>
                {/* </div> */}
                {/* <div className="row mt-2"> */}
                <div className="dashboard__item">
                    <div className="stat-box">
                        <div className="title">{t("Rooms Available")}</div>
                        <div className="value">450</div>
                    </div>
                </div>
                <div className="dashboard__item">
                    <div className="stat-box">
                        <div className="title">{t("Average Temperature")}</div>
                        <div className="value">19 &#8451;</div>
                    </div>
                </div>
                {/* </div> */}
            </div>
            {/* <!-- * Stats --> */}

        </Fragment>
    )
}

export default Dashboard
