import React, { Fragment, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { isOnlineNetworkActions } from 'src/redux/globalState_redux/globalState_action';


import { BrowserRouter, Switch, Route, Redirect, useLocation, Link } from "react-router-dom";
import Cookies from 'js-cookie';


import { useIsAuthenticated } from "@azure/msal-react";

// // import Layout Wrapper
import Layout from 'src/components/layout/Layout';

// // import screens 
import SplashScreen from 'src/containers/auth/splashScreen/SplashScreen';
import Dashboard from './screen/dashboard/Dashboard';
import ApartmentList from './screen/apartmentList/ApartmentList';
import RoomList from './screen/roomList/RoomList';
import RoomSpecific from './screen/roomSpecific/RoomSpecific';
import Settings from './screen/settings/Settings';
import Notification from "./screen/notification/Notification"
import AboutUs from './screen/aboutUs/AboutUs';

// // IOT SCREEN



function RootNavigation() {
    const dispatch = useDispatch()

    useEffect(() => {
        if (window.navigator.onLine) {
            // // dispatch Network status online while loading app (i.e. first time)
            dispatch(isOnlineNetworkActions("online"))
        }
        else {
            // // dispatch Network status offline while loading app (i.e. first time)
            dispatch(isOnlineNetworkActions("offline"))
        }

        window.addEventListener('online', function (e) {
            // // dispatch Network status online while network switches from offline to online
            dispatch(isOnlineNetworkActions("online"))
        });
        window.addEventListener('offline', function (e) {
            // // dispatch Network status offline while network switches from online to offline
            dispatch(isOnlineNetworkActions("offline"))
        });
    }, [])


    const ProtectedRoute = (props) => {
        const isAuthenticated = useIsAuthenticated();
        let userToken = Cookies.get('levikairaAuth');

        const location = useLocation();
        let isAuthLogin = isAuthenticated || userToken
        return (
            <Fragment>
                {isAuthLogin ?
                    (
                        <Route {...props} />
                    ) : (
                        <Redirect to={{ pathname: "/", state: { from: location } }} />
                    )
                }
            </Fragment>
        )
    };

    const api_regex = /^\/api\/.*/
    // if using "/api/" in the pathname, don't use React Router
    if (api_regex.test(window.location.pathname)) {
        return <div /> // must return at least an empty div
    } else {
        // use React Router
        return (
            <Fragment>
                <BrowserRouter>
                    <Layout>
                        <Switch>
                            <ProtectedRoute exact path="/dashboard" ><Dashboard /></ProtectedRoute>
                            <ProtectedRoute exact path="/apartmentlist" ><ApartmentList /></ProtectedRoute>
                            <ProtectedRoute exact path="/settings" ><Settings /></ProtectedRoute>
                            <ProtectedRoute exact path="/notifications" ><Notification /></ProtectedRoute>
                            <ProtectedRoute exact path="/about" ><AboutUs /></ProtectedRoute>
                            <ProtectedRoute exact path="/apartment/:apartmentId" ><RoomList /></ProtectedRoute>
                            <ProtectedRoute exact path="/roomspecific/:apartmentId/:roomId" ><RoomSpecific /></ProtectedRoute>
                            <Route path="/splash"><SplashScreen /></Route>
                            <Route path="/"><SplashScreen /></Route>
                        </Switch>
                    </Layout>
                </BrowserRouter>
            </Fragment>
        )
    }
}

export default RootNavigation

