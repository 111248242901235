import { Fragment, useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomLoader from '../loadingIndicator/CustomLoader';
import SideNavbarMenue from 'src/components/navbarMenu/sideNavbarMenu/SideNavbarMenu';
import BottomNavbarMenu from 'src/components/navbarMenu/bottomMenue/BottomNavbarMenu';
import Header from 'src/components/navbarMenu/header/Header';
import Cookies from 'js-cookie';
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useDispatch } from 'react-redux';
import { sideNavbarMenueShowActions } from 'src/redux/globalState_redux/globalState_action';



function Layout(props) {

  const dispatch = useDispatch()
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  let userToken = Cookies.get('levikairaAuth');
  let isAuthLogin = isAuthenticated || userToken

  return (
    <Fragment >
      <ToastContainer />
      <CustomLoader />
      {isAuthLogin &&
        <Fragment>
          <Header />
          <SideNavbarMenue />
          <BottomNavbarMenu />
        </Fragment>
      }
      <main style={main_Style} onClick={() => dispatch(sideNavbarMenueShowActions(false))}>{props.children}</main>
    </Fragment>
  );
}

export default Layout;


const main_Style = {
  minHeight: "80vh"
};